
    import axios from "axios";
    import { registra_log } from "./DSi_login";

    var url =  sessionStorage['url']


    async function get_db(){
        // var db =  'cfc_'+sessionStorage['lg:SIZE']
        var ide =  sessionStorage['lg:SIZE'] / 777
        var db = 'cfc_'+ide
        
        // alert(db)
        // console.log('lg:IDE:', db);
        return db
    };


    export async function get_dados_usuario(){
       let  obj_user = {}
       try{
           obj_user = JSON.parse(sessionStorage['obj_user'])
        //    console.log('obj_user:', obj_user);
           return obj_user
           
       }catch(e){
        console.log('erro no get_dados_usuario:', e);
        return false
       }
    };




    //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
    export async function  salvar_tabela(that,tabela, obj_edita, dialog_name, obj_before){

        // console.log('salvar_tabela - obj_before:', obj_before);

        if (dialog_name){
            that[dialog_name] = false
        }

        // console.log('salvar_tabela:',obj_edita);

        //GLOBAL
        if (obj_edita.CODIGO >0 ){
            // that.tabela_update(tabela, that.item_edita)
            return  await that.tabela_update(tabela, obj_edita, obj_before)
             
        }else{
            return  await that.tabela_create(tabela, obj_edita)
        }
    };

    export async function editar_tabela(that, item, obj_edita_name, dialog_name) {
        // that.items_clicou = item //ver se vou usar isso aqui
    
        that[obj_edita_name] = Object.assign({}, item)
        that[dialog_name] = true
    };

    export function nova_tabela(that, obj_edita_name,dialog_name){

        // for (var member in obj_edita)  obj_edita[member]= '..'; 
        that[obj_edita_name] = {}

        that[dialog_name] = true

    };

    export async function remover_tabela(that, tabela, obj_edita, titulo){
        //GLOBAL
        // console.log(obj_edita);
        let ide = obj_edita.CODIGO
        if (ide > 0){
            if (confirm("Deseja realmente excluir "+titulo+' - '+ide+' ?')){

                let sql = 'delete from '+tabela+' where codigo ='+ide
                await that.crud_sql(sql)

                await that.tabela(tabela,'delete')

                await salva_log('delete', tabela, obj_edita)
            }
        }
    };


    //EXECUTAR UM SQL TIPO UPDATE
    export async function crud_sql (that, sql, show_alerta ) {

        let db = await get_db()
        // const res = await axios.post(url+'crud.php', {
        const res = await axios.post(url+'crud.php?DB_NAME='+db, {
            acao:   6,
            meuSQL: sql
        })
    

        // console.log(res.data);
        if (show_alerta!='N'){
           that?.alerta(res.data, "success");//so retorna ok ou error
        }

        return res.data
    };


    //ABRE OS DADOS DA TABELA
    export async function crud_abrir_tabela(that, sql, items_name){
        //GLOBAL    
        // console.log('c1:',items_name);

        let db = await get_db()

        if (items_name){
            that[items_name] = []//limpa antes
        }

        var meuSQL = sql
        // console.log(meuSQL, db);
                
        // const res = await axios.post(url+'crud.php', {
        const res = await axios.post(url+'crud.php?DB_NAME='+db, {
            acao:   5,
            meuSQL: meuSQL
        })


        if (items_name){
            that[items_name] = await res.data 
            // console.log(that[items_name]);
            // console.log('c2',items_name);
        }

        // console.log('crud_abrir_tabela:', res.data);
        return await res.data
    };


    export async function tabela_update(that, tabela, obj_edita, obj_before){
        //GLOBAL

        // console.log('tabela_update - obj_before:', obj_before);

        let db = await get_db()

        let id = obj_edita.CODIGO
        if (id<1){
          that.alerta('Código inválido!','red')
          return false
        }
        else{

            const data = {
                    acao: 3,
                    id:           id,
                    id_field:    'CODIGO', 
                    table:       tabela,
                    fields:      obj_edita,
            }  

            // const res = await axios.post(url+'crud.php', data);
            const res = await axios.post(url+'crud.php?DB_NAME='+db, data)
            // console.log('tabela_update:', res.data);
            

            if (res.data.includes('ERROR')){
                that?.alerta(res.data, "red");
                return false
            }
            else{
                that?.alerta(res.data, "success");

                await salva_log('update', tabela, obj_edita, obj_before)//antes do refresh da tabela

                if (['alunos','servicos','whats_respostas'].includes(tabela)){
                  await that?.tabela_2(tabela,'update')//close open janela cadastro.vue
                }else{
                  await that?.tabela(tabela,'update')//close open
                }
    
                return true
            }


        }
    };

    export async function tabela_create_no_refresh(that, tabela, obj_edita){

        let db = await get_db()

        // console.log(`tabela_create_no_refresh ${tabela} :`, obj_edita);

        // defino alguns campos default
        if (tabela == 'conta'){
            let obj_user = await get_dados_usuario()
            // console.log('obj_user.CODIGO:', obj_user.CODIGO);
            obj_edita = Object.assign({}, obj_edita, { CODIGO_USUARIO: obj_user.CODIGO });
        }


        const data = {
                acao: 2,
                table:             tabela,
                fields:            obj_edita,
        }  
        const res = await axios.post(url+'crud.php?DB_NAME='+db, data)     
        
            if (res.data.includes('ERROR')){
                that?.alerta(res.data, "red");
                return false
            }
            else{
                that?.alerta(res.data, "success");

                // await salva_log('create', tabela, obj_edita)
    
                return true
            }
   
        // aqui ele somente posta...nao fica atualizando..uso isso quando gero parcelas dos alunos
    };



    export async function tabela_create(that, tabela, obj_edita){

        let db = await get_db()

        // console.log(`tabela_create ${tabela} :`, obj_edita);
        

        // defino alguns campos default
        if (tabela == 'conta'){
            let obj_user = await get_dados_usuario()
            // console.log('obj_user.CODIGO:', obj_user.CODIGO);
            obj_edita = Object.assign({}, obj_edita, { CODIGO_USUARIO: obj_user.CODIGO });
        }


        const data = {
                acao: 2,
                table:             tabela,
                fields:            obj_edita,
        }  


        // const res = await axios.post(url+'crud.php', data);
        const res = await axios.post(url+'crud.php?DB_NAME='+db, data)

        console.log('tabela_create:', res.data);

        if (res.data.includes('ERROR')){
            that?.alerta(res.data, "red");
            return false
        }
        else{
            that?.alerta(res.data, "success");

                
            // if (tabela == 'alunos') {
            if (['alunos','servicos','whats_respostas'].includes(tabela)){
              await that?.tabela_2(tabela,'create')//close open janela cadastro.vue
            }
            else{
              await that?.tabela(tabela,'create')//close open
            }

            await salva_log('create', tabela, obj_edita)

            return true
        }


    };


    export async function ler_session_php(that, session_name,to){

        let res = await axios.post(url+'meuSESSION.php', {acao: session_name});
        if (to){
          that[to] = res.data;//referencio o objeto pelo nome
        }
        // console.log(res.data);
        return res
    };

    export async function crud_empresa(that){
        

        let sql = `SELECT *  FROM empresa` 
        let r = await crud_abrir_tabela(that, sql, '')

        await definir(r)

        async function definir(dados){
          
                dados = dados[0]


                dados.ENDERECO_COMPLETO = dados.ENDERECO + ',  CEP: '+ dados.CEP +', '+dados.CIDADE +' - '+ dados.UF

                sessionStorage['obj_dados_empresa']  =  JSON.stringify(dados)


                //   console.log('DSisql - crud_empresa:', dados);
                //   console.log(dados);

                // TITULO DO PROGRAMA
                document.title = 'DSi - '+ dados.NOME
            
                sessionStorage['emp_title']    = 'DSi - '+ dados.NOME

                sessionStorage['emp_ide']      = dados.IDE
                sessionStorage['emp_nome']     = dados.NOME
                sessionStorage['emp_razao']    = dados.RAZAO_SOCIAL
                sessionStorage['emp_fone1']    = dados.FONE1
                sessionStorage['emp_fone2']    = dados.FONE2
                sessionStorage['emp_celular']  = dados.CELULAR
                sessionStorage['emp_cnpj']     = dados.CNPJ
                sessionStorage['emp_cep']      = dados.CEP
                sessionStorage['emp_endereco'] = dados.ENDERECO +', CEP:'+ dados.CEP + ', '+dados.CIDADE+'-'+dados.UF
                sessionStorage['emp_cidade']   = dados.CIDADE
                sessionStorage['emp_uf']       = dados.UF
                sessionStorage['emp_email']    = dados.EMAIL
                sessionStorage['emp_logo']     = dados.PATH_LOGO
                sessionStorage['emp_multa']    = dados.MULTA?.replaceAll(',','.')
                sessionStorage['emp_juros']    = dados.JUROS?.replaceAll(',','.')

                //logo   
                sessionStorage['emp_url_logo'] = sessionStorage['url']+'images/'+ dados.PATH_LOGO

                //configuracoes
                sessionStorage['conf_mostrar_juros'] = dados.C_FIN_VL_SIMPLES

                sessionStorage['emp_pix_plataforma']     = dados.PIX_PLATAFORMA
                sessionStorage['emp_pix_situacao']       = dados.PIX_SITUACAO
                

                //salvo no sessionStorage objeto em formato string
                sessionStorage['config_emp']  =  JSON.stringify(dados)


                // asaas
                // caso tenha alguma plataforma de cobrança..pega os dados pix
                if (dados.PIX_PLATAFORMA){

                    let sql =  `SELECT * from tabela_cobranca where PIX_PLATAFORMA = '${dados.PIX_PLATAFORMA}'`
                    let dados_pix = null
                    if (dados_pix = await crud_abrir_tabela(that, sql, '')){
                        sessionStorage['config_pix']  =  JSON.stringify(dados_pix[0])
                    }   
                }


        }

        // console.log(r);
        return await r

      };

      export async function salva_log(tipo, tabela, item, item_before){
         
         let espaco = `⠀`
        //  alert(tabela+' em .js')
        //  console.log('item:', item);
        //  console.log('item_before:', item_before);
        //  if (!item_before){
        //     alert('nao definido')
        //  }

         let campos_monitorar

         if (tabela == 'alunos'){
            if (tipo == 'create'){
               await registra_log('CADASTRO', negritoO('CADASTROU ','blue') +  negritoO(item.NOME,false) )
            }
            else if (tipo == 'update'){

                // verifico se alterou algum campo especifico
                // console.log('item_before:', item_before);
                // let alteracoes = await compararObjetos(item_before, item) 
                // if (alteracoes){
                //     await registra_log('CADASTRO', `ALTEROU CADASTRO ${alteracoes}` )//pega todas as altercoes
                // }

                if ((!item_before) || (Object.keys(item_before).length === 0)){ 
                    return false 
                }

                if (item.NOME != item_before.NOME){//pego somente alteracoes no NOME
                    await registra_log('CADASTRO', negritoO('ALTEROU CADASTRO','blue') + ` antes ${item_before.NOME} agora ${item.NOME}`, item.CODIGO_ALUNO ) 
                }
            }
         }

         else if (tabela == 'conta'){

            if (tipo == 'create'){
               await registra_log('FINANCEIRO', `${(item.TIPO == 'S')?  negritoO('PAGOU','red')  : negritoO('ADICIONOU  no FINANCEIRO','blue') }   (${negritoO(item?.double_TOTAL)}) em ${item?.FORMA_PAGAMENTO} ${(item?.CATEGORIA)||''} 
                                                 ${item?.SERVICO_REFERENTE} VENC.${formata_data(item?.DATA_VENCIMENTO)}  - EFET.${ (formata_data(item?.DATA_EFETUADO) || 'PENDENTE') }` )
            }
            else if (tipo == 'update'){

                // console.log('item_before:', item_before);
                if ((!item_before) || (Object.keys(item_before).length === 0)){ 
                    return false 
                }
                

                campos_monitorar = ['double_TOTAL','SERVICO_REFERENTE','FORMA_PAGAMENTO','OBSERVACAO','CATEGORIA','CONTA_REFERENTE','DATA_VENCIMENTO','DATA_EFETUADO']
                // if (item_before.PAGO == 'S'){//se estiver pago.monitora campos data efetuado
                //     campos_monitorar.push('DATA_EFETUADO') //adiciono esse campo para monitorar
                // }

                
                let alteracoes = await compararObjetos(item_before, item, campos_monitorar) 
                if (alteracoes){
                    await registra_log('FINANCEIRO', negritoO('ALTEROU PARCELA', 'blue')+ ` Nº${item.CODIGO} ${alteracoes}` )//pega todas as altercoes
                }

            }
            else if (tipo == 'delete'){
                // console.log('delete item:', item);
                await registra_log('FINANCEIRO',  negritoO('EXCLUIU','red') + ` ${ (item.TIPO == 'S') ?'SAIDA':'ENTRADA' } Nº${item?.CODIGO}    (R$ ${item?.double_TOTAL}) VENC.${item?.x_DATA_VENCIMENTO} REC.${(formata_data(item?.DATA_EFETUADO) || 'PENDENTE')} de 
                                    ${ (item.X_NOME)||(item.X_NOME_ALUNO)||'' }:${ (item.CODIGO_ALUNO)||'' }  ${(item.CATEGORIA)||''} ${item.SERVICO_REFERENTE}`  )
            }
         }

         else if (tabela == 'servicos'){
            if (tipo == 'create'){
               await registra_log('CADASTRO', `${negritoO('NOVO SERVIÇO', 'blue')} - ${item?.DESCRICAO}  (R$ ${item?.double_TOTAL} ) para ${item?.X_NOME}:${item.CODIGO_ALUNO}`, item.CODIGO_ALUNO )

            }
            else if (tipo == 'update'){
                // await registra_log('CADASTRO', `ALTEROU ${item.NOME}` )
            }
         }
      };


      function formata_data(entra){
        
        try{
            let partes = entra.split("-");
            let dataInvertida = partes[2] + "/" + partes[1] + "/" + partes[0];
            return dataInvertida
        }
        catch (err) {
            return entra
        }
      };

      export function negritoO(entra, color){
        if(entra){
            if (color){
                color = ` ${color}--text `
            }
            entra = `<b class="font-weight-black ${color} ">${entra}</b>`
        }

        return entra
      };

      export async function compararObjetos(objetoAntigo, objetoNovo, somenteEsses) {
        let mudancas = [];
        let todasChaves = new Set([...Object.keys(objetoAntigo), ...Object.keys(objetoNovo)]);

        todasChaves.forEach(chave => {
            let valorAntigo = objetoAntigo[chave];
            let valorNovo = objetoNovo[chave];
            if (valorAntigo !== valorNovo) {


                // TRATO OS CAMPOS DATA PARA DEIXAR NO FORMATO BR
                if (chave.includes('DATA_')){
                    valorAntigo = formata_data(valorAntigo)
                    valorNovo   = formata_data(valorNovo)

                    if (valorAntigo == '00/00/0000'){ 
                        valorAntigo = negritoO('PENDENTE')
                        valorNovo   = negritoO(`EFETUADO em `+valorNovo, 'green')
                    }

                    if ((valorNovo == '00/00/0000') || (valorNovo == null)){ 
                        valorNovo   = negritoO('PENDENTE', 'red')
                        valorAntigo = negritoO(`EFETUADO em `+valorAntigo )
                    }

                }


                if (!somenteEsses){//monitora tudo
                  mudancas.push(`(${chave}) de ${valorAntigo} para ${valorNovo}`);
                }
                else{
                    if (somenteEsses.includes(chave)){//somente alguns campos
                        mudancas.push(`(${chave}) de ${valorAntigo} para ${valorNovo}`);
                    }
                }
            }
        });
    
        // console.log('mudancas:', mudancas);
        // Verifica se houve alguma mudança e retorna a string correspondente
        return mudancas.length > 0 ? mudancas.join(', ') : false;
    };




    export default {
        salvar_tabela,
        editar_tabela,
        nova_tabela,
        remover_tabela,
        tabela_update,
        tabela_create,
        tabela_create_no_refresh,
        crud_sql,
        crud_abrir_tabela,
        ler_session_php,
        crud_empresa
    };


